import React from 'react'

import { Link } from "gatsby"
import { normalize } from "../../hooks/normalize"

import Layout from "../share/layout";
import Footer from "../share/footer"
import { BlogContainer, NavContainer, MainContainer } from "./styles";
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, TumblrShareButton, PinterestShareButton } from "react-share";
import { useLocation } from "@reach/router";
import CategoriesResponsive from "../arteycultura/CategoriesResponsive";

const Blog = ({ pageContext: { dataSingle, type, _program } }) => {
  const {
    name,
    link,
    desc,
    program,
    temp,
    eps,
    i,
    j
  } = dataSingle;

  console.log({ name, link, desc, program, temp, eps, i, j, type, _program })

  const { pathname } = useLocation()
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
      },
      [INLINES.HYPERLINK]: (node) => {
        if ((node.data.uri).includes("<iframe")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div className="rich-content-iframe" dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    },
    renderMark: {},
  }

  return (
    <Layout>
      <div class="upcc-container upcc-program">

        <section class="upcc-menu">
          <nav>
            <Link to={`/`}>Inicio</Link>&nbsp;/&nbsp;

            {type == 'program'
              ? <span>
                  <Link to={`/programas`}>Programas</Link>&nbsp;/&nbsp;
                  <Link to={`/programas/${normalize(program.name)}`}>{ program.name }</Link>&nbsp;/&nbsp;
                  <Link to={`/programas/${normalize(program.name)}/${normalize(name)}`}>{ name }</Link>
                </span>
              : <span>
                  <Link to={`/podcasts`}>Podcasts</Link>&nbsp;/&nbsp;
                  <Link to={`/podcasts/${normalize(program.name)}`}>{ program.name }</Link>&nbsp;/&nbsp;
                  <Link to={`/podcasts/${normalize(program.name)}/${normalize(name)}`}>{ name }</Link>
                </span>
            }
          </nav>
        </section>


        <section class="wall dark">
          <h1 class="upcc-h1 white center">{ program.name }</h1>
          <h3 class="upcc-h3 white center">T{ i + 1 }E{ j + 1 } - { name }</h3>

          <div class="iframe-container">
            <div class="iframe">
              <iframe src={ link } allow="fullscreen;"/>
            </div>
          </div>

          {desc
            ? <p class="desc">{ desc }</p>
            : <i/>
          }

        </section>

        <section class="wall dark">
          <div class="inner">

            <div class="wall-col">

              <Link class="left small">
                <img class="upcc-img program" src={ program.image.file.url }/>
              </Link>

              <div class="right">
                <h1 class="upcc-h1 white">{ program.name }</h1>

                <p>
                  Sobre el programa: <br/>
                  { program.description }
                </p> <br/>

                <p>
                  { program.credits.map((text, i) => <li>{ text } <br/></li>) }
                </p>

              </div>

            </div>

            <a class="more white center" href="#more">VER MÁS<br/><i class="fa fa-angle-down" id="more"></i></a>
          </div>
        </section>
        { i }
        { j }
        <main>
          {
            _program.temps.map(( temp, _i ) => {
              return (
              <div>

                <section class="upcc-controls">
                  <div class="left">
                    <span class="bar"></span>
                    <h5 class="upcc-h5 white">Temporada { _i + 1 } { temp.name }</h5>
                  </div>
                </section> <br/>

                <div class="upcc-list">
                  {
                    temp.eps.map(( ep, _j ) => {

                      if(type == 'program') {

                        if(i == _i && j == _j) {

                          return (
                            <Link to={`/programas/${normalize(program.name)}/${normalize(ep.name)}`} class="upcc-element">
                              T{ _i + 1}E{ _j + 1 } - { ep.name } gaaa!! <i class="fa fa-play-circle"></i>
                            </Link>
                          )

                        } else {

                          return (
                            <Link to={`/programas/${normalize(program.name)}/${normalize(ep.name)}`} class="upcc-element">
                              T{ _i + 1}E{ _j + 1 } - { ep.name } <i class="fa fa-play-circle"></i>
                            </Link>
                          )

                        }

                      } else {
                        return (
                          <Link to={`/podcasts/${ normalize(program.name) }/${ normalize(ep.name) }`} class="upcc-element">
                            E{ _j + 1 } - { ep.name } <i class="fa fa-play-circle"></i>
                          </Link>
                        )
                      }
                    })
                  }
                </div> <br/><br/>
              </div>
              )
            })
          } <br/><br/><br/><br/><br/><br/>
        </main>

      </div>
      <Footer />
    </Layout>
  )
}

export default Blog
